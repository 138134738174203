@use '../utils/index';

/*----------------------------------------*/
/*  09. COUNTER CSS START
/*----------------------------------------*/

.tp-counter{
    &-wrapper{
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 0px 20px 80px rgba(7, 13, 32, 0.08);
        backdrop-filter: blur(5px);
        border-radius: 100px;
        border: 1px solid var(--tp-common-white);
    }
    &-space{
        @media #{$xs}{
            padding-bottom: 100px;
        } 
    }
    &-item{
        & p{
            margin-bottom: 0;
            font-family: var(--tp-ff-urban);
            font-weight: 500;
            font-size: 18px;
            line-height: 20px; 
            letter-spacing: 0.04em; 
            @media #{$xs}{
                font-size: 16px;
            }
        }
        & h4{
            font-weight: 400;
            font-size: 40px;
            line-height: 50px;
            font-style: normal;
            margin-bottom: 0;
            font-family: var(--tp-ff-dm);
            & span{
                font-family: var(--tp-ff-dm);
                font-style: italic;
                font-weight: 400;
                font-size: 60px;
                line-height: 50px;
                letter-spacing: -0.02em;
                @media #{$xs}{
                    font-size: 45px;
                }
            }
            & em{
                font-weight: 400;
                font-size: 60px;
                line-height: 50px;
                font-style: italic;
                font-family: var(--tp-ff-dm); 
            }
        }
    }
    &-shape-1{
        position: absolute;
        top: 17%;
        left: 16.5%;
        z-index: -1;
        @media #{$xxxl}{
            left: 9.5%;
        }
        @media #{$xxl}{
            left: 9.5%;
        }
        @media #{$xl}{
            left: 2.5%;
        }
        @media #{$lg}{
            left: -0.5%;
        }
    }
    &-shape-2{
        position: absolute;
        bottom: 37%;
        right: 36%;
        z-index: -1;
        animation: rotate2 10s linear infinite;
        @media #{$md}{
            top: -5%;
            left: 22%;
        }
    }
    &-shape-3{
        position: absolute;
        top: -19%;
        right: 21%;
        z-index: -1;
    }
    &-shape-4{
        position: absolute;
        top: 27%;
        left: 18.5%;
        z-index: -1;
        @media #{$xxxl}{
            right: 12.5%;
        }
        @media #{$xxl}{
            right: 11.5%;
        }
        @media #{$xl}{
            right: 9.5%;
        }
        @media #{$lg}{
            top: -12%;
            right: 0.5%;
        }
    }
}

.tp-counter-wrap {
	border-right: 1px solid #EDEFF5;
	padding: 40px 0;
    @media #{$xs}{
        margin-bottom: 20px;
        border-right: none;
    }
}

.tp-counter-wrapper{
    & .row{
        [class*="col-"]{
            &:last-child{
                & .tp-counter-wrap{
                    border-right: 0;
                }
            }
        }
    }
}

.tp-fun-fact{
    &-item{
        & h4{
            font-weight: 700;
            font-size: 50px;
            line-height: 48px;
            color: var(--tp-common-white);
            padding-right: 20px;
            position: relative;
            @media #{$lg}{
                font-size: 40px;
            }
            @media #{$md}{
                font-size: 31px;
            }
            @media #{$xs}{
                font-size: 35px;
            }
            &::after{
                position: absolute;
                top: -23px;
                left: -22px;
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background: linear-gradient(145.27deg, rgba(255, 255, 255, 0.1) 15.55%, rgba(255, 255, 255, 0) 86.81%);
                content: "";
                @media #{$md}{
                    top: -15px;
                    left: -22px;
                    height: 80px;
                    width: 80px;
                }
                @media #{$xs}{
                    top: -17px;
                    left: -10px;
                    height: 80px;
                    width: 80px;
                }
            }
        }
        & p{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 0;
            @media #{$xs}{
                & br{
                    display: none;
                }
            }
        }
    }
    &-space-1{
        padding-left: 100px;
        @media #{$lg}{
            padding-left: 30px;
        }
        @media #{$md}{
            padding-left: 0px;
        }
        @media #{$xs}{
            padding-left: 0px;
        }
    }
    &-space-2{
        padding-left: 70px;
        @media #{$lg}{
            padding-left: 30px;
        }
        @media #{$md}{
            padding-left: 0px;
        }
        @media #{$xs}{
            padding-left: 0px;
        }
    }
    &-space-3{
        padding-left: 70px;
        @media #{$md}{
            padding-left: 35px;
        }
        @media #{$xs}{
            padding-left: 0px;
        }
    }
}
.tp-counter-br{
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    @media #{$lg,$md,$xs}{
        border: 0;
    }
}

.tp-fun-fact-area{
    & .container{
        & .row{
            [class*="col-"]{
                &:last-child{
                    border-right: 0;
                }
            }
        }
    }
}

.tp-vedio-bg{
    padding-top: 265px;
    padding-bottom: 265px;
    border-radius: 20px;
    background-repeat: no-repeat;
    @media #{$xs}{
        padding-top: 150px;
        padding-bottom: 150px;
    }
}
.tp-vedio-grey-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 310px;
    z-index: -1;
}
.tp-vedio-play-btn{
    & a{
        height: 130px;
        width: 130px;
        background-color: var(--tp-common-yellow-3);
        text-align: center;
        line-height: 130px;
        color: var(--tp-common-black);
        display: inline-block;
        border-radius: 50%;
        font-size: 25px;
        @media #{$xs}{
            height: 100px;
            width: 100px;
            line-height: 100px;
            font-size: 18px;
        }
        & a{
            margin-left: 10px;
        }
    }
}

.swiper-slide{
    transition: .3s;
}
.dragged .swiper-slide{
    transform: scale(.97);
}


.tp-fun-fact-2{
    & .tp-fun-fact-item{
        & h4{
            color: var(--tp-common-black);
            &::after{
                background: linear-gradient(145.27deg, rgba(133, 117, 224, 0.2) 15.55%, rgba(185, 173, 255, 0) 86.81%);
                border-radius: 100px;
            }
        }
        & p{
            color: var(--tp-common-black);
        }
    }
    & .tp-counter-br{
        border-color: rgba(20, 17, 37, 0.1);
    }
}
.tp-fun-fact-title{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}