//Fonts
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "slick-carousel/slick/slick.css";
@import "swiper/swiper-bundle.css";
@import "swiper/swiper.min.css";
@import "swiper/swiper.css";
@import "../utils/index";
@import "fonts";
@import "variables";
@import "avatar";
@import "helper";
@import "menu";
@import "footer";
@import "contact";
@import "screenshot";
@import "faqs";
@import "service";
@import "section-title";
@import "animation";
@import "spacing";
@import "theme";
@import "background";
@import "hero";
@import "buttons";
@import "footer";
@import "testimonial";
@import "counter";
@import "rank";

@import "../css/meanmenu.css";
@import "../css/spacing.css";
@import "../css/animate.css";
@import "../css/nice-select.css";
@import "../css/custom-animation.css";
